<template>
  <el-drawer
    title="Количество на складах"
    :visible="isVisible"
    size="40%"
    @close="$emit('close')"
  >
    <div v-if="data.isAvailable" :class="$style.drawerItems">
      <div :class="$style.drawerItem">{{ data.deliveryText }}</div>
      <div :class="$style.drawerItem">{{ data.selfDeliveryText }}</div>
      <div :class="$style.drawerItem">{{ data.showroomStateText }}</div>
    </div>
    <div v-else :class="$style.drawerItems">Нет в наличии</div>
    <el-table :data="data.stocks || []" style="width: 100%" show-summary>
      <el-table-column prop="warehouse" label="Склад"> </el-table-column>
      <el-table-column prop="stock" label="Доступно"> </el-table-column>
      <el-table-column prop="reserve" label="Резерв"> </el-table-column>
      <el-table-column prop="inTransit" label="Ожидание"> </el-table-column>
    </el-table>
  </el-drawer>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    isVisible: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style lang="scss" module>
.drawerItems {
  padding: 0 1rem;
  .drawerItem {
    margin: 0.5rem 0;
  }
  table {
    width: 100%;
    tr > td:first-child {
      font-weight: bold;
    }
  }
}
</style>