<template>
  <div :class="$style.products">
    <div>
      <el-dialog
        title="При импорте продуктов произошла ошибка"
        :visible.sync="isOpenUploadXlsxModal"
      >
        <h3>{{ uploadFileErrors?.message }}</h3>
        <el-table :data="uploadFileErrors.errors" max-height="500">
          <el-table-column label="№ строки" property="number" width="100">
          </el-table-column>
          <el-table-column label="Id продукта" property="id" width="200">
          </el-table-column>
          <el-table-column label="Ошибка" property="error"> </el-table-column>
        </el-table>
      </el-dialog>
    </div>
    <div :class="$style.wrapper">
      <div :class="$style.filters">
        <div :class="$style.search">
          <Autocomplete
            :search="search"
            placeholder="Поиск"
            :valueNames="['name']"
            @querySearch="querySearch"
            @selectItem="handleSelectProduct"
            @handleFindItems="handleFindProducts"
            @input="search = $event"
          />
          <el-button
            :class="$style.button"
            style="margin-left: 0; margin-bottom: 0; border-radius: 0"
            type="primary"
            @click="setCatalog"
          >
            Показать
          </el-button>
        </div>
        <div style="position: relative">
          <el-button
            :class="$style.button"
            type="primary"
            @click="handleClickFilters"
          >
            Фильтры
          </el-button>
          <DropdownFilters
            v-model="filters"
            v-if="isFiltersOpen"
            title="Фильтры поиска товаров"
            :options="filtersOptions"
            @applyFilters="applyFilters"
            @resetFilters="resetFilters"
            @close="isFiltersOpen = false"
            @findSupplier="findSupplier"
            @loadMoreSuppliers="loadMoreSuppliers"
            @clearSupplier="clearSupplier"
            :isOpen="isFiltersOpen"
          />
        </div>
        <el-button
          :class="$style.button"
          type="primary"
          @click="isOpenUpdateProductsModal = true"
          :disabled="!selectedProducts.length"
        >
          Обновить
        </el-button>
      </div>
      <div>
        <input type="file" ref="file" name="filename" accept=".xlsx" />
      </div>
      <el-button
        :class="$style.button"
        type="primary"
        plain
        @click="uploadXLSX()"
      >
        Импорт XLSX
      </el-button>

      <el-button
        :class="$style.button"
        type="primary"
        plain
        @click="downloadProductsXLSX()"
      >
        Экспорт XLSX
      </el-button>
      <el-button
        :class="$style.button"
        type="info"
        plain
        @click="gotoExternalCatalog('yandex_market_catalog')"
      >
        Каталог ЯМ
      </el-button>
      <el-button
        :class="$style.button"
        type="info"
        plain
        @click="gotoExternalCatalog('google_merchant_catalog')"
      >
        Каталог GM
      </el-button>
      <el-button
        :class="$style.button"
        type="primary"
        @click="
          $router.push($options.ADDWINE_ADMIN_ROUTES.CATALOG.PRODUCTS.CREATE)
        "
      >
        Создать
      </el-button>
    </div>
    <div :class="$style.count">
      Общее количество товаров: <span>{{ total }}</span>
    </div>
    <el-table
      :data="products"
      stripe
      :header-row-class-name="$style.tableHeader"
      @selection-change="handleSelectionChange($event)"
    >
      <el-table-column
        type="selection"
        width="55"
        :class-name="$style.tableCellCenter"
        header-align="center"
      >
      </el-table-column>
      <el-table-column label="Приоритет" width="120"
        ><template slot-scope="scope">
          {{ scope.row.priority ?? 0 }}
        </template></el-table-column
      >
      <el-table-column label="Изображение" width="120"
        ><template slot-scope="scope">
          <Gallery
            :images="setGalleryImages(scope.row?.images)"
            :image="setupImagesListData(scope.row?.images)?.original"
          /> </template
      ></el-table-column>
      <el-table-column
        prop="vendorCode"
        label="Артикул"
        width="120"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="name" show-overflow-tooltip label="Название">
      </el-table-column>
      <el-table-column
        label="Себестоимость"
        width="110"
        :class-name="$style.tableCellCenter"
      >
        <template slot-scope="scope">
          {{
            scope.row.costPrice != null ? scope.row.costPrice.toFixed(2) : null
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="price"
        header-align="center"
        label="Цена"
        width="110"
        :class-name="$style.tableCellCenter"
      >
      </el-table-column>
      <el-table-column
        header-align="center"
        label="Остаток"
        width="110"
        :class-name="$style.stock"
      >
        <template slot-scope="scope">
          <span style="cursor: pointer" @click="openProductStocks(scope.row)">
            {{
              scope.row.stocks && scope.row.stocks.length
                ? scope.row.stocks[0].stock
                : ''
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        label="После скидки"
        width="110"
        :class-name="$style.tableCellCenter"
      >
        <template slot-scope="scope">
          {{
            scope.row.discountPrice != null
              ? scope.row.discountPrice.toFixed(2)
              : null
          }}
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        label="Подарок"
        width="80"
        :class-name="$style.tableCellCenter"
      >
        <template slot-scope="scope">
          <input
            type="checkbox"
            :checked="scope.row.present"
            style="pointer-events: none"
          />
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        label="Active"
        width="80"
        :class-name="$style.tableCellCenter"
      >
        <template slot-scope="scope">
          <input
            type="checkbox"
            :checked="scope.row.isActive"
            style="pointer-events: none"
          />
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        label="GM"
        width="70"
        :class-name="$style.tableCellCenter"
      >
        <template slot-scope="scope">
          <input
            type="checkbox"
            :checked="scope.row.isInGM"
            style="pointer-events: none"
          />
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        label="YM"
        width="70"
        :class-name="$style.tableCellCenter"
      >
        <template slot-scope="scope">
          <input
            type="checkbox"
            :checked="scope.row.isInYM"
            style="pointer-events: none"
          />
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        label="Предзаказы"
        width="100"
        :class-name="$style.tableCellCenter"
      >
        <template slot-scope="scope">
          <router-link
            :to="
              getRoute({
                route: $options.ADDWINE_ADMIN_ROUTES.CATALOG.PRODUCTS.PREORDER,
                params: { id: scope.row.id },
              })
            "
            style="margin: 0 0.5rem"
          >
            Перейти
          </router-link>
        </template>
      </el-table-column>
      <el-table-column width="115" :class-name="$style.actions">
        <template slot="header">
          <Icon
            v-if="selectedProducts.length"
            name="trash"
            @click="removeSelectedProducts"
            :class="$style.remove"
          />
        </template>
        <template slot-scope="scope">
          <ActionButtons
            :edit-link="
              getRoute({
                route: $options.ADDWINE_ADMIN_ROUTES.CATALOG.PRODUCTS.PRODUCT,
                params: { id: scope.row.id },
              })
            "
            @delete="remove(scope.row.id)"
            :view-link="
              getRoute({
                route: $options.ADDWINE_ROUTES.CATALOG.PRODUCT,
                params: { id: scope.row.slug },
                site: $options.PROJECTS.ADDWINE,
              })
            "
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="setCatalog"
      background
    >
    </el-pagination>
    <ProductStocksModal
      v-if="isOpenWarehouseModal"
      :is-visible="isOpenWarehouseModal"
      :data="productStocksData"
      @close="isOpenWarehouseModal = false"
    />

    <el-dialog
      title="Изменение продуктов по параметрам"
      :visible.sync="isOpenUpdateProductsModal"
      :custom-class="$style.updateProductsModal"
      @closed="resetUpdateOptions"
    >
      <span :class="$style.intro"
        >Сначала выберите какие параметры хотите обновить, а потом установите их
        новые значения</span
      >
      <div :class="$style.form">
        <div :class="$style.flags">
          <el-checkbox
            v-model="updateOptions.active.isInclude"
            :class="$style.include"
          />
          <span>Активный:</span>
          <el-checkbox
            v-model="updateOptions.active.newValue"
            :class="$style.value"
          />
          <span>- новое значение</span>
        </div>
        <div :class="$style.flags">
          <el-checkbox
            v-model="updateOptions.googleMerchant.isInclude"
            :class="$style.include"
          />
          <span>Включён в каталог Google Merchant:</span>
          <el-checkbox
            v-model="updateOptions.googleMerchant.newValue"
            :class="$style.value"
          />
          <span>- новое значение</span>
        </div>
        <div :class="$style.flags">
          <el-checkbox
            v-model="updateOptions.yandexMarket.isInclude"
            :class="$style.include"
          />
          <span>Включён в каталог Yandex Market:</span>
          <el-checkbox
            v-model="updateOptions.yandexMarket.newValue"
            :class="$style.value"
          />
          <span>- новое значение</span>
        </div>
      </div>
      <el-button
        type="primary"
        :class="$style.button"
        @click="updateProducts"
        :disabled="isProductsFlagsUpdateDisabled()"
      >
        Обновить продукты
      </el-button>
    </el-dialog>
  </div>
</template>

<script>
import delivery from '@/delivery'

import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Autocomplete from '@/components/atoms/Autocomplete'
import DropdownFilters from '@/components/atoms/DropdownFilters.vue'
import Icon from '@/components/atoms/Icon'
import ProductStocksModal from '@/components/moleculs/ProductStocksModal.vue'
import Gallery from '@/components/atoms/Gallery.vue'

import { parseProductPresence } from '@/helpers/addwine/products'
import { mapState } from 'vuex'
import {
  getRoute,
  ADDWINE_ADMIN_ROUTES,
  ADDWINE_ROUTES,
} from '@/constants/routing'
import { PROJECTS } from '@/constants/projects'
import images from '@/mixins/images'
import addQueryParamsAddwine from '@/mixins/addQueryParamsAddwine'

export default {
  mixins: [images, addQueryParamsAddwine(function(params) { this.getCatalog(params); })],
  components: {
    DropdownFilters,
    ActionButtons,
    Autocomplete,
    Icon,
    ProductStocksModal,
    Gallery,
  },
  ADDWINE_ADMIN_ROUTES,
  ADDWINE_ROUTES,
  PROJECTS,
  data() {
    return {
      query: {},
      products: [],
      total: 0,
      page: 1,
      scrollPosition: 0,
      limit: 100,
      search: '',
      priority: 0,
      isFiltersOpen: false,
      filterOptions: {
        brands: [],
        categories: [],
        assortments: [],
        suppliers: [],
      },
      filters: {
        brands: [],
        categories: [],
        assortments: [],
        supplier: '',
        isAvailable: false,
        havePresents: false,
        isActive: false,
      },
      updateOptions: {
        googleMerchant: {
          isInclude: false,
          newValue: false,
        },
        yandexMarket: {
          isInclude: false,
          newValue: false,
        },
        active: {
          isInclude: false,
          newValue: false,
        },
      },
      selectedProducts: [],
      isOpenUpdateProductsModal: false,
      productStocksData: null,
      isOpenWarehouseModal: false,
      uploadFileErrors: {
        message: '',
        errors: [],
      },
      isOpenUploadXlsxModal: false,
    }
  },
  mounted() {
    this.setFiltersOptions();    
  },
  created() {
    this.setCatalog()
  },
  computed: {
    ...mapState({
      filtersOptions: (state) => state.filtersOptions,
    }),
  },
  methods: {
    getScrollPosition() {
      return window.scrollY;
    },
    initializeFromQuery() {
      this.page = parseInt(this.$route.query.page, 10) || 1
      this.search = this.$route.query.search || ''
      this.filters = {
        brands: this.$route.query.brands || [],
        categories: this.$route.query.categories || [],
        assortments: this.$route.query.assortments || [],
        supplier: this.$route.query.supplier || '',
        isAvailable: this.$route.query.isAvailable === 'true',
        havePresents: this.$route.query.havePresents === 'true',
        isActive: this.$route.query.isActive === 'true',
        haveExpressDelivery: this.$route.query.haveExpressDelivery === 'true'
      }
      this.limit = this.$route.query.limit || 100
      this.orderBy = this.$route.query.orderBy || 'priority'
      this.orderDir = this.$route.query.orderDir || 'desc'
    },
    async clearSupplier() {
      await this.findSupplier('')
    },
    async findSupplier(name) {
      if (!name) {
        this.filtersOptions.suppliers = await this.getSuppliers({
          page: 1,
          limit: 30,
        })
        this.filtersOptions.suppliers.meta.page = 1
      }

      if (name.length > 2) {
        const suppliers = await this.getSuppliers({
          page: 1,
          limit: 30,
          search: name,
        })
        this.filtersOptions.suppliers = suppliers
        this.filtersOptions.suppliers.meta.page = 1
        this.filtersOptions.suppliers.name = name
      }
    },

    async loadMoreSuppliers() {
      if (
        30 * this.filtersOptions.suppliers.meta.page <=
        this.filtersOptions.suppliers.meta.count
      ) {
        this.filtersOptions.suppliers.meta.page++
        const query = {
          page: this.filtersOptions.suppliers.meta.page,
          limit: 30,
          search: this.filtersOptions?.suppliers?.name ?? undefined,
        }
        const suppliers = await this.getSuppliers(query)
        suppliers.data.forEach((supplier) => {
          if (!this.filtersOptions.suppliers.data.find((x) => x === supplier))
            this.filtersOptions.suppliers.data.push(supplier)
        })
      }
    },

    async getSuppliers(query) {
      const { value, error } =
        await delivery.ProductsCore.ProductsActions.getProductsSuppliers(query)
      if (error) return
      return value
    },

    setFiltersOptions() {
      this.$store.dispatch('getFiltersOptions')
    },
    async getCatalog() {
      const loading = this.$loading({
        lock: true,
      })

      const query = {
        limit: this.limit,
        page: this.page,
        orderBy: 'priority',
        orderDir: 'desc',
        scrollY: window.scrollY,
        ...this.filters,
      }

      if (this.search) {
        query.search = this.search
      }

      const { value, error } =
        await delivery.ProductsCore.CatalogsActions.getCatalog(query)

          this.updatedQueryParams(query);

      loading.close()
      return { value, error }
    },
    async setCatalog() {
      const { value, error } = await this.getCatalog()

      if (error) return

      this.products = value.products.data
      this.total = value.products.meta.count
    },
    async remove(id) {
      const query = {
        ids: [id],
      }
      const { error } = await delivery.ProductsCore.ProductsActions.delete(
        query,
      )
      if (!error) {
        await this.setCatalog()
      }
    },
    async removeSelectedProducts() {
      let isConfirmRemoveAll
      const pages = this.total / this.limit

      if (this.selectedProducts.length === this.limit && pages > 1) {
        isConfirmRemoveAll = confirm(
          'Удалить все продукты, соответствующие фильтрам?',
        )
      }

      if (isConfirmRemoveAll) {
        const filters = {
          ...this.filters,
          search: this.search,
        }

        const { error } =
          await delivery.ProductsCore.ProductsActions.deleteByFilters(filters)

        if (error) {
          alert('Не удалось удалить продукты по фильтрам')
          return
        }
        await this.setCatalog()
      }

      const selectedProductsIds = this.selectedProducts.map(
        (selectedProduct) => selectedProduct.id,
      )

      const query = {
        ids: selectedProductsIds,
      }

      const { error } = await delivery.ProductsCore.ProductsActions.delete(
        query,
      )

      if (!error) await this.setCatalog()
    },
    async downloadProductsXLSX() {
      this.error = ''
      this.isLoading = true
      const query = {
        search: this.search,
        ...this.filters,
      }

      const result = await delivery.ProductsCore.SynchronizationActions.getXLSX(
        query,
      )

      if (result.error) {
        this.error = result.error
      } else {
        const link = document.createElement('a')

        const href = window.URL.createObjectURL(result.value)

        link.href = href
        link.setAttribute('download', 'products.xlsx')
        link.click()
        link.remove()
        window.URL.revokeObjectURL(href)
      }
      this.isLoading = false
    },
    async uploadXLSX() {
      this.isLoading = true
      if (!this.$refs.file.files[0]) {
        alert('Файл для импорта не выбран.')
        this.isLoading = false
        return
      }

      try {
        const response =
          await delivery.ProductsCore.SynchronizationActions.uploadXLSX(
            this.$refs.file.files[0],
          )

        if (response.error) {
          this.isOpenUploadXlsxModal = true

          const errorsList = response.error.details.map((x) => {
            const details = x.split(':')
            const productId = details[1]?.split(' ')
            const errors = x.split('DETAILS:')

            return {
              number: details[0] ?? '',
              id: productId[productId.length - 1] ?? '',
              error: errors[1] ?? '',
            }
          })

          this.uploadFileErrors = {
            message: response.error.message,
            errors: errorsList,
          }
        } else {
          alert('Продукты успешно импортированы.\n')
        }
      } catch (error) {
        this.isOpenUploadXlsxModal = true
      } finally {
        this.isLoading = false
        this.$refs.file.value = null
      }
    },
    gotoExternalCatalog(link) {
      window.open(
        this.$configData.api_link +
          'api/product_service/v2/synchronization/' +
          link,
      )
    },
    async querySearch({ queryString, setSearchItems }) {
      const { value, error } =
        await delivery.ProductsCore.CatalogsActions.getCatalog({
          limit: 20,
          page: this.page,
          search: queryString,
        })

      if (error) return

      setSearchItems(value.products.data)
    },
    handleSelectProduct(selectedProduct) {
      this.$router.push('/addwine/catalog/products/' + selectedProduct.id)
    },
    async handleFindProducts() {
      await this.setCatalog()
    },
    handleClickFilters() {
      this.isFiltersOpen = !this.isFiltersOpen
    },
    resetFilters() {
      this.filters = {
        brands: [],
        categories: [],
        assortments: [],
        isAvailable: false,
        havePresents: false,
        isActive: false,
        suppliers: [],
      }
    },
    async applyFilters() {
      await this.setCatalog()
      this.isFiltersOpen = false
    },
    handleSelectionChange(items) {
      this.selectedProducts = items
    },
    async updateProducts() {
      const flags = {}

      if (this.updateOptions.active.isInclude) {
        flags.isActive = this.updateOptions.active.newValue.toString()
      }

      if (this.updateOptions.googleMerchant.isInclude) {
        flags.isInGM = this.updateOptions.googleMerchant.newValue.toString()
      }

      if (this.updateOptions.yandexMarket.isInclude) {
        flags.isInYM = this.updateOptions.yandexMarket.newValue.toString()
      }

      let isConfirmUpdateAll
      const pages = this.total / this.limit

      if (this.selectedProducts.length === this.limit && pages > 1) {
        isConfirmUpdateAll = confirm(
          'Обновить все продукты, соответствующие фильтрам?',
        )
      }

      if (isConfirmUpdateAll) {
        const filters = {
          ...this.filters,
          search: this.search,
        }

        const { error } =
          await delivery.ProductsCore.ProductsActions.updateFlagsByFilters(
            filters,
            flags,
          )

        if (error) {
          alert('Не удалось обновить продукты по фильтрам')
          return
        }
        this.isOpenUpdateProductsModal = false
        await this.setCatalog()
        this.resetUpdateOptions()
        return
      }

      const selectedProductsIds = this.selectedProducts.map(
        (selectedProduct) => selectedProduct.id,
      )

      const query = {
        ids: selectedProductsIds,
        flags: JSON.stringify(flags),
      }

      const { error } = await delivery.ProductsCore.ProductsActions.updateFlags(
        query,
      )

      if (error) {
        alert('Не удалось обновить выбранные продукты')
        return
      }
      this.isOpenUpdateProductsModal = false
      await this.setCatalog()
      this.resetUpdateOptions()
    },
    isProductsFlagsUpdateDisabled() {
      return (
        !this.updateOptions.active.isInclude &&
        !this.updateOptions.googleMerchant.isInclude &&
        !this.updateOptions.yandexMarket.isInclude
      )
    },
    resetUpdateOptions() {
      this.updateOptions = {
        googleMerchant: {
          isInclude: false,
          newValue: false,
        },
        yandexMarket: {
          isInclude: false,
          newValue: false,
        },
        active: {
          isInclude: false,
          newValue: false,
        },
      }
    },
    async openProductStocks(product) {
      if (product.stocks?.length) {
        const { value, error } =
          await delivery.ProductsCore.ProductsActions.getProductStocks(
            product.id,
          )
        if (error) return

        const deliveryTexts = parseProductPresence(product.presence)

        this.productStocksData = {
          stocks: value.data,
          isAvailable: product.isAvailable,
          showroomStateText: deliveryTexts.showroomStateText,
          deliveryText: deliveryTexts.deliveryText,
          selfDeliveryText: deliveryTexts.selfDeliveryText,
        }

        this.isOpenWarehouseModal = true
      }
    },
    getRoute({ route, params, site }) {
      return getRoute({ route, params, site })
    },
    setGalleryImages(images) {
      if (!images) {
        return []
      }

      return images.map((img) => this.$configData.s3_link + img.original)
    },
  },
}
</script>

<style lang="scss" module>
.products {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
    @include baseShadow;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0.5rem;
    flex-wrap: wrap;
    gap: 5px;
    padding: 1rem 0.5rem;
    .button {
      margin-right: 1rem;
      margin-left: 0;
      .icon {
        width: 1rem;
        height: 1rem;
        fill: $light-gray;
      }
    }
    .filters {
      display: flex;
      align-items: center;
      margin-right: auto;
      .search {
        display: flex;
        align-items: center;
        input {
          border-radius: 0;
        }
      }
    }
  }
  .count {
    margin-bottom: 1rem;
  }
  .pagination {
    @include pagination;
  }
  .tableHeader {
    font-size: 12px;
  }
  .tableCellCenter {
    text-align: center;
  }
  .stock {
    text-align: center;
    & > div > span {
      cursor: pointer;
      border-bottom: 0.063rem solid $black;
    }
  }
  .actions {
    & > div > svg {
      cursor: pointer;
      margin-left: 1.875rem;
    }
  }
  .updateProductsModal {
    .intro {
      margin-bottom: 0.5rem;
    }
    .button {
      margin: 0 auto;
      display: block;
    }
    .form {
      margin: 1rem 0;
      .flags {
        .include {
          margin-right: 0.75rem;
        }
        .value {
          margin: 0 0.5rem;
        }
      }
    }
  }
}
</style>
